import { Button, Center, Stack, Text, ThemeIcon } from "@mantine/core";

import React from "react";
import { useT, useSetLang } from "../../i18n/index";
import { IconCheck, IconX } from "@tabler/icons";
import { Link, useSearchParams } from "react-router-dom";

const Error = () => {
  const t = useT();
  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");

  return (
    <Center className="success-modal">
      <Stack
        sx={{
          backgroundColor: "white",
          width: 520,
          height: 366,
          borderRadius: 10,
          boxShadow: "0px 3px 6px #00000029;",
        }}
        justify="center"
        align="center"
        spacing={5}
      >
        <ThemeIcon radius={100} size={80} color="red">
          <IconX />
        </ThemeIcon>{" "}
        <Text color="#000000" size={25}>
          {t("ERROR")}
        </Text>
        <Text mb={5} size={14}>
          {message} {t("CONTACT_BANK")}
        </Text>
        <Link to="/packages">
          <Button sx={{ width: 200, height: 40 }}> {t("CONTINUE")}</Button>
        </Link>
      </Stack>
    </Center>
  );
};

export default Error;
