import { useEffect, useState } from "react";
import { Grid, Button, Loader, Select } from "@mantine/core";
import * as yup from "yup";

import SectionTitle from "./Components/SectionTitle";
import { useGeUserPackages, useGetProfile } from "../../api/auth";
import { pathToUrl } from "../../utils/router";
import { apiRoutes } from "../../routes";
import { useT } from "../../i18n/index";
import { usePost } from "../../utils/reactQuery";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import { getLocations } from "../../endpoints/api";
import { useAuth } from "../../utils/auth";

const ChangeLocation = () => {
  const { data: user, isLoading } = useGetProfile();
  const { data: packages } = useGeUserPackages();
  const updateLocation = usePost(
    pathToUrl(apiRoutes.updateLocation, { id: user?.id })
  );
  const [touched, setTouched] = useState(false);
  const auth = useAuth();
  const t = useT();
  const {
    data: locations,
    isLoading: isLocationsLoading,
    isFetched: isLocationsFetched,
  } = useQuery(["locations"], () => getLocations(auth?.user?.club_id), {});

  // locations array
  const locationsArray =
    isLocationsFetched &&
    locations?.locations.map((location: any) => ({
      value: location.id.toString(),
      label: location.name,
    }));
  //Form validation
  const validationSchema = yup.object().shape({
    location: yup.string().required(t("SELECT_LOCATION")),
  });

  const formik = useFormik({
    initialValues: {
      location: user?.home_location_id,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      const newUser = {
        ...user,
        home_location_id: parseInt(values.location),
      };
      updateLocation.mutate({ user: newUser, club_id: auth?.user?.club_id });
      setTouched(false);
    },
  });

  const handleChangeLocation = (e: any) => {
    if (!touched) setTouched(true);
    formik.setFieldValue("location", e);
  };

  const handleSubmission = async () => {
    if (touched) formik.handleSubmit();
  };

  if (isLocationsLoading) return <Loader color="orange" />;
  return (
    <Grid gutter="xl">
      <Grid.Col xs={12} md={6}>
        <SectionTitle text={t("LOCATION")} />
        <Grid gutter="md" sx={{ marginTop: "20px" }}>
          <Grid.Col xs={10}>
            <Select
              placeholder={t("SELECT_BRANCH")}
              label={t("LOCATION")}
              name="location"
              disabled={
                packages?.count === 0 || user?.home_location_id === null
                  ? false
                  : true
              }
              data={locationsArray}
              value={
                typeof formik.values.location === "number"
                  ? formik.values.location.toString()
                  : formik.values.location
              }
              onChange={handleChangeLocation}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12}>
        {/*Save Button */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              fontSize: ".875rem",
              height: "40px",
              width: "130px",
              marginInlineEnd: "10px",
            }}
            onClick={handleSubmission}
            disabled={updateLocation.isLoading || !touched}
          >
            {updateLocation.isLoading && (
              <Loader
                color="orange"
                size="xs"
                sx={{ marginInlineEnd: "5px" }}
              />
            )}
            {t("save")}
          </Button>
        </div>
      </Grid.Col>
    </Grid>
  );
};

export default ChangeLocation;
