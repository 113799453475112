import { apiRoutes } from "../routes";
import { api } from "../utils/api";
import axios from "axios";

import { ProfileInterface } from "../interfaces/auth";
import { PackageInterface } from "../interfaces/index";
import { useFetch } from "../utils/reactQuery";

export const getTokenByPassword = (email: string, password: string) => {
  return axios.post<{ access_token: string }>(apiRoutes.getTokenByPassword, {
    email,
    password,
  });
};
export const forgotPassword = (email: string) => {
  return axios.post<{ access_token: string }>(apiRoutes.forgotPassword, {
    email,
  });
};
export const useGetProfile = () => {
  const context = useFetch<{ user: ProfileInterface }>(apiRoutes.getProfile, {
    retry: true,
    // refetchOnWindowFocus: true,
    // keepPreviousData: true,
  });
  return { ...context, data: context.data?.user };
};

export const useGeUserPackages = () => {
  const context = useFetch<{
    client_service_packages: [PackageInterface];
    count: number;
  }>(apiRoutes.getPackages, {
    retry: false,
  });
  return { ...context, data: context.data };
};
