import React from "react";
import { Group, Card, Image, Text, Badge } from "@mantine/core";

export default function NoData(props: noDataProps) {
  return (
    <div className="container mt-3">
      <Card shadow="sm" p="lg" radius="sm" withBorder mt={0}>
        <div className="d-flex flex-column justify content-center align-items-center">
          <div className="no-data-icon">
            <img className="" src={props.img} />
          </div>

          <Text color="#AAAAAA" size="sm" mt={15} weight={500}>
            {props.msg}
          </Text>
        </div>
      </Card>
    </div>
  );
}
type noDataProps = {
  img: any;
  msg: string;
};
