import { RouteInterface } from "../interfaces/index";
import LoggedInLayout from "../Layouts/LoggedIn";
import DefaultLayout from "../Layouts/Default";
import { Navigate, Outlet } from "react-router-dom";
// import { useAuth } from "../utils/reactQuery";
import { useAuth } from "../utils/auth";

export default function PrivateOutlet() {
  const auth = useAuth();

  return auth?.user?.token ? (
    <LoggedInLayout>
      <Outlet />
    </LoggedInLayout>
  ) : (
    <Navigate to="/" />
  );
}
