export function getLocationByName() {}

export const sortByOrderTag = (a: any, b: any) => {
    try {
      var orderA = a.cost ? a.cost?.amount : a.custom_price?.amount;
      var orderB = b.cost?.amount ? b.cost?.amount : b.custom_price?.amount;
      orderA = parseInt(orderA);
      orderB = parseInt(orderB);
      return orderA - orderB;
    } catch (error) {
      return 0;
    }
  };
