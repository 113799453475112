import React, { ReactNode } from "react";

import Navbar from "../Components/Navbar";

interface Props {
  children?: ReactNode;
}

const LoggedInLayout = ({ children }: Props) => {
  return (
    <>
      <Navbar />
      <div className="mt-2 p-4 main-layout-wrapper">{children}</div>
    </>
  );
};

export default LoggedInLayout;
