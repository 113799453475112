import React, { ReactElement, useState } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "./scss/stylesheet.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider, createEmotionCache } from "@mantine/core";
import { theme } from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { LangProvider } from "./i18n/index";
import rtlPlugin from "stylis-plugin-rtl";
import { T, useT, useSetLang, useLang } from "./i18n/index";
import { AuthProvider, useAuth } from "./utils/auth";
import { hotjar } from "react-hotjar";
import { NotificationsProvider } from "@mantine/notifications";

hotjar.initialize(3390715, 3390715);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

function Idx() {
  return (
    <QueryClientProvider client={client}>
      <ReactQueryDevtools />
      <LangProvider>
        <AuthProvider>
          <Dock />
        </AuthProvider>
      </LangProvider>
    </QueryClientProvider>
  );
}

const rtlCache = createEmotionCache({
  key: "mantine-rtl",
  stylisPlugins: [rtlPlugin],
});

function Dock() {
  const lang = useLang();
  const auth = useAuth();
  return (
    <MantineProvider
      theme={Object.assign(theme, { dir: lang == "ar" ? "rtl" : "ltr" })}
      withGlobalStyles
      withNormalizeCSS
      emotionCache={lang == "ar" ? rtlCache : undefined}
    >
      <NotificationsProvider>
        <App />
      </NotificationsProvider>
    </MantineProvider>
  );
}

root.render(<Idx />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
