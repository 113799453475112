export const apiRoutes = {
  getTokenByPassword: "https://www.activgyms.com/en/wp-json/api/login",
  forgotPassword: "https://www.activgyms.com/en/wp-json/api/forgot-password",
  getProfile: "profile",
  updateProfile: "/profile",
  getClasses: "/ocurrencies",
  getClass: "occurrences/:id",
  joinClass: "occurrences/:id",
  getServices: "/api/getServices",
  getCarDetail: "/api/getCarDetail/:id",
  getInsurance: "/api/getInsurance/:id",
  job: "/api/job/:id?",
  getTransactions: "/transactions",
  getPackages: "/client-packages",
  getExpiredPackages: "/client-expired-packages",
  terminatePackage: "/client-packages/:id/terminate",
  changePassword: "/change-password",
  updateLocation:"/user-locations/:id/change"
};

export const pageRoutes = {
  main: "/",
  auth: "/auth",
  appointment: "/appointment/:id",
  occurrencies: "/classes",
  home: "/home",
  dashboard: "/dashboard",
};
