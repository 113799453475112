import React from "react";
import {
  Card,
  Stack,
  Title,
  Group,
  Divider,
  Text,
  Anchor,
  Skeleton,
  MediaQuery,
} from "@mantine/core";
import { format } from "date-fns";
import { Dashboard } from "../../Styles";
import bank from "./../../assets/bank.png";
import { DashboardNodata } from "./DashboardNodata";
import { useT, useLang } from "../../i18n";
import { Link } from "react-router-dom";

const LatestPayments = (props: dashboardProps) => {
  const { classes } = Dashboard();
  const t = useT();

  return (
    <>
      <Card className={classes.card} radius="md" py={30} px={20}>
        <Skeleton
          width="70%"
          height="10%"
          sx={{
            "@media (max-width: 500px)": {
              paddingBottom: 40,
            },
          }}
          px={7}
          mb={10}
          visible={props.fetchedStatus === 1 ? true : false}
        >
          <Stack align="flex-start" spacing={0}>
            <Title className={classes.title} order={3}>
              {t("LATEST_PAYMENTS")}
            </Title>

            <Divider color="#F92524" size="xl" mt={7} sx={{ width: 84 }} />
          </Stack>
        </Skeleton>
        {props.noData ? (
          <DashboardNodata img={bank} msg={t("NO_PAYMENT_DATA")} />
        ) : (
          <>
            {" "}
            <MediaQuery smallerThan={500} styles={{ display: "none" }}>
              <Group ml={7} mb={28} align="flex-start" spacing={40}>
                <Group spacing={20}>
                  <Stack spacing={10} align="flex-start">
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10}>{t("POSTED_DATE")}</Text>
                    </Skeleton>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10}>{t("AMOUNT_PAID")}</Text>
                    </Skeleton>
                  </Stack>

                  <Stack spacing={10}>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10} color="default.8">
                        {props?.fetchedData?.created_at &&
                          format(
                            new Date(props?.fetchedData?.created_at),
                            "yyyy-MM-dd @ hh:mm aa"
                          )}
                      </Text>
                    </Skeleton>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10} color="default.8">
                        {props.fetchedData.amount} {t("SAR")}
                      </Text>
                    </Skeleton>
                  </Stack>
                </Group>

                <Group spacing={10}>
                  <Stack spacing={10}>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10}>{t("SERVICE")}</Text>
                    </Skeleton>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10}>{t("RECIEPT")}</Text>
                    </Skeleton>
                  </Stack>
                  <Stack spacing={10}>
                    {" "}
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10} color="default.8">
                        {props.fetchedData.product_name}
                      </Text>
                    </Skeleton>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                      height={22}
                      pl={5}
                    >
                      <Anchor
                        color="default.2"
                        size={14}
                        underline
                        href={props?.fetchedData?.receipt_url}
                        target="_blank"
                      >
                        {t("VIEW")}
                      </Anchor>
                    </Skeleton>
                  </Stack>
                </Group>
              </Group>
            </MediaQuery>
            <MediaQuery largerThan={500} styles={{ display: "none" }}>
              <Group ml={4} mb={20} align="flex-start" spacing={7}>
                <Group spacing={5}>
                  <Stack spacing={5} align="flex-start">
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10}>{t("POSTED_DATE")}</Text>
                    </Skeleton>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10}>{t("AMOUNT_PAID")}</Text>
                    </Skeleton>
                  </Stack>

                  <Stack spacing={5}>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10} color="default.8">
                        {props.fetchedData.created_at}
                      </Text>
                    </Skeleton>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10} color="default.8">
                        {props.fetchedData.amount} {t("SAR")}
                      </Text>
                    </Skeleton>
                  </Stack>
                </Group>

                <Group spacing={5}>
                  <Stack spacing={5}>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10}>{t("SERVICE")}</Text>
                    </Skeleton>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10}>{t("RECIEPT")}</Text>
                    </Skeleton>
                  </Stack>
                  <Stack spacing={5}>
                    {" "}
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Text size={10} color="default.8">
                        {props.fetchedData.product_name}
                      </Text>
                    </Skeleton>
                    <Skeleton
                      visible={props.fetchedStatus === 1 ? true : false}
                    >
                      <Anchor
                        color="default.2"
                        size={10}
                        underline
                        href="#"
                        target="_blank"
                      >
                        {t("VIEW")}
                      </Anchor>
                    </Skeleton>
                  </Stack>
                </Group>
              </Group>
            </MediaQuery>
            <Anchor
              color="default.2"
              size={10}
              underline
              component={Link}
              to="/account-settings"
              sx={{ alignSelf: "flex-end" }}
            >
              {t("VIEW_TRANSACTIONS")}
            </Anchor>{" "}
          </>
        )}
      </Card>{" "}
    </>
  );
};

export default LatestPayments;
type dashboardProps = {
  fetchedData: any;
  fetchedStatus: number;
  noData: Boolean;
};
