import { navStyles } from "../Styles";
import { useState, useEffect, useMemo } from "react";
import { Link, redirect } from "react-router-dom";
import { Anchor, Drawer, Stack, ActionIcon, MediaQuery } from "@mantine/core";
import { IconMenu2 } from "@tabler/icons";
import { IconChevronDown } from "@tabler/icons";
import { Avatar, Group, Text, Menu, Image } from "@mantine/core";
import { useGetProfile } from "../api/auth";
import { T, useT, useSetLang, useLang } from "../i18n/index";
import { useQuery, useIsFetching, useQueryClient } from "react-query";
import Instagram from "./../assets/images/instagram.svg";
import Logo from "./../assets/images/Logo.svg";
import { theme } from "../theme";
import NotificationBar from "./NotificationBar";
import { useLocalStorage } from "../utils/reactQuery";
import { useAuth } from "./../utils/auth";
import { getUserProfile } from "../endpoints/api";
import { setAxiosTokenInterceptor } from "../endpoints/api";
import { setAxiosTokenInterceptor as setAxiosToken } from "../utils/api";
import { useNavigate } from "react-router-dom";
import {
  getPackages,
  getPTPackages,
  getUserPackages,
  getRenwalPackages,
  getTransactions,
  getFounderRenwalPackages,
} from "../endpoints/api";

function Navbar() {
  const queryClient = useQueryClient();
  const { classes } = navStyles();
  const [opened, setOpened] = useState(false);
  const {
    data: user,
    isLoading,
    isFetching,
  } = useQuery(["profile"], () => getUserProfile(), {
    retry: true,
    // refetchOnWindowFocus: true,
    keepPreviousData: true,
  });

  const t = useT();
  let navigate = useNavigate();
  const setLang = useSetLang();
  const lang = useLang();
  const [token, setToken] = useLocalStorage("token");
  const auth = useAuth();

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(lang === "ar" ? "rtl" : "ltr");
  }, [lang]);

  useEffect(() => {
    if (user?.user) {
      const locale = user?.user?.locale ? user.user.locale : "ar";

      if (locale !== lang) {
        setLang(locale);
      }
    }
  }, [user, lang, setLang]);

  const { data: transactions } = useQuery(
    ["transactions"],
    () => getTransactions(),
    {
      refetchOnWindowFocus: true,
    }
  );

  const PAYMENT_METHODS_IDS = useMemo(() => {
    return [62, 63, 65];
  }, []);

  const ND_PACKAGES_IDS = useMemo(() => {
    return [512, 513, 509];
  }, []);

  const ndTransaction = useMemo(() => {
    if (transactions) {
      return transactions?.data?.find(
        (t: any) =>
          (t.product_name.startsWith("ND") ||
            t.product_name.startsWith("Monthly")) &&
          (t.payment_method !== "61" ||
            PAYMENT_METHODS_IDS.includes(t.payment_method))
      );
    }
  }, [transactions, PAYMENT_METHODS_IDS]);

  const { data: userPackages } = useQuery(
    ["userPackages"],
    () => getUserPackages(),
    {
      refetchOnWindowFocus: true,
    }
  );

  // useEffect(() => {
  //   if (ndPackage && ndTransaction) {
  //     navigate("/packages");
  //   }
  // }, [ndPackage, ndTransaction, navigate, user]);

  const handleUserLogOut = () => {
    queryClient.clear();
    auth?.signout();
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className="container-fluid main-nav-wrapper">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <ActionIcon
              className={classes.drawr}
              onClick={() => setOpened(true)}
            >
              <IconMenu2 size={20} color="white" />
            </ActionIcon>
            <Link to="/dashboard">
              <img className={classes.img} src={Logo} alt="logo" />
            </Link>

            <Group className={classes.nav} spacing={30}>
              <Anchor
                className={classes.navAnchor}
                variant="text"
                size="sm"
                component={Link}
                to="/dashboard"
              >
                {t("DASHBOARD")}
              </Anchor>
              <Anchor
                className={classes.navAnchor}
                variant="text"
                size="sm"
                component={Link}
                to="/classes"
              >
                {t("CLASSES")}
              </Anchor>
              <Anchor
                className={classes.navAnchor}
                variant="text"
                size="sm"
                component={Link}
                to="/trainers"
              >
                {t("TRAINERS")}
              </Anchor>
              <Anchor
                className={classes.navAnchor}
                variant="text"
                size="sm"
                component={Link}
                to="/appointments"
              >
                {t("APPOINTMENTS")}
              </Anchor>
              <Anchor
                className={classes.navAnchor}
                variant="text"
                size="sm"
                component={Link}
                to="/packages"
              >
                {t("PACKAGES")}
              </Anchor>
            </Group>
            <Group className={classes.social} ml={100}>
              <Anchor
                href="https://www.instagram.com/activgymsa/?hl=en"
                target="_blank"
                size={16}
              >
                <img src={Instagram} alt="inst"></img>
              </Anchor>
              <Anchor
                onClick={() => setLang(lang === "ar" ? "en" : "ar")}
                variant="text"
                underline
                size={16}
                sx={{
                  borderLeft: "2px solid white",
                  paddingLeft: 10,
                  fontFamily: "Cairo",
                  marginLeft: 10,
                }}
              >
                {t("LANGUAGE")}
              </Anchor>
            </Group>
          </div>
        </div>

        <Drawer
          opened={opened}
          onClose={() => setOpened(false)}
          padding="lg"
          size="sm"
          styles={{ drawer: { backgroundColor: "black", color: "white" } }}
        >
          <Stack spacing={20}>
            <Anchor
              className={classes.navAnchor}
              variant="text"
              size="sm"
              component={Link}
              to="/dashboard"
              onClick={() => setOpened(false)}
            >
              {t("DASHBOARD")}
            </Anchor>
            <Anchor
              className={classes.navAnchor}
              variant="text"
              size="sm"
              component={Link}
              to="/classes"
              onClick={() => setOpened(false)}
            >
              {t("CLASSES")}
            </Anchor>
            <Anchor
              className={classes.navAnchor}
              variant="text"
              size="sm"
              component={Link}
              to="/trainers"
              onClick={() => setOpened(false)}
            >
              {t("TRAINERS")}
            </Anchor>
            <Anchor
              className={classes.navAnchor}
              variant="text"
              size="sm"
              component={Link}
              to="/appointments"
              onClick={() => setOpened(false)}
            >
              {t("APPOINTMENTS")}
            </Anchor>
            <Anchor
              className={classes.navAnchor}
              variant="text"
              size="sm"
              component={Link}
              to="/packages"
              onClick={() => setOpened(false)}
            >
              {t("PACKAGES")}
            </Anchor>
            <Group mt={20}>
              <Anchor
                href="https://www.instagram.com/activgymsa/?hl=en"
                size={16}
              >
                <img src={Instagram} alt="inst"></img>
              </Anchor>
              <Anchor
                onClick={() => {
                  setOpened(false);
                  setLang(lang === "ar" ? "en" : "ar");
                }}
                variant="text"
                underline
                size={16}
                sx={{
                  borderLeft: "2px solid white",
                  paddingLeft: 10,
                  fontFamily: "Cairo",
                  marginLeft: 10,
                }}
              >
                {t("LANGUAGE")}
              </Anchor>
            </Group>
          </Stack>
        </Drawer>
      </div>
      <NotificationBar />
      <MediaQuery largerThan={500} styles={{ display: "none" }}>
        <Group
          className="welcome-component-wrapper mx-2"
          position="left"
          pt={20}
        >
          <div className="d-flex flex-row align-items-center">
            {!isFetching && user?.user?.image ? (
              <div style={{ width: "50px", height: "50px", marginTop: "0px" }}>
                <Image
                  alt={user?.user?.name}
                  src={user.user.image}
                  width="50px"
                  height="50px"
                  withPlaceholder
                  styles={{
                    image: { borderRadius: "100%", minHeight: "50px" },
                  }}
                />
              </div>
            ) : (
              <Avatar size="lg" radius="xl"></Avatar>
            )}

            <Text size={15} className="ml-2">
              {t("GOOD_MORNING")}, {!isFetching && user?.user?.name}
            </Text>

            <Menu
              position="top-end"
              shadow="md"
              width={200}
              styles={{
                dropdown: {
                  marginTop: theme.dir == "rtl" ? 140 : 140,
                  marginRight: theme.dir == "rtl" ? 23 : 20,
                  marginLeft: theme.dir == "rtl" ? 100 : 130,
                  borderRadius: 13,
                  cursor: "pointer",
                },
                item: {
                  fontSize: 12,
                  textAlign: "center",
                },
                divider: {
                  backgroundColor: "#F1563C",
                  height: 2,
                  width: 180,
                  marginInline: 4,
                },
              }}
            >
              <Menu.Target>
                <IconChevronDown color="Red" />
              </Menu.Target>

              <Menu.Dropdown>
                <Link to="/account-settings" style={{ textDecoration: "none" }}>
                  <Menu.Item>{t("ACCOUNT_SETTINGS")}</Menu.Item>
                </Link>

                <Menu.Divider />
                <Menu.Item component="button" onClick={handleUserLogOut}>
                  {t("LOGOUT")}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Group>
      </MediaQuery>
      <MediaQuery smallerThan={500} styles={{ display: "none" }}>
        <Group position="right" mr={100} pt={20}>
          {!isFetching && user?.user?.image ? (
            <div style={{ width: "50px", height: "50px", marginTop: "0px" }}>
              <Image
                alt={user?.user?.name}
                src={user.user.image}
                width="50px"
                height="50px"
                withPlaceholder
                styles={{
                  image: { borderRadius: "100%", minHeight: "50px" },
                }}
              />
            </div>
          ) : (
            <Avatar size="lg" radius="xl"></Avatar>
          )}

          <Text>
            {t("GOOD_MORNING")}, {!isFetching && user?.user?.name}
          </Text>

          <Menu
            position="top-end"
            shadow="md"
            width={200}
            styles={{
              dropdown: {
                marginTop: theme.dir == "rtl" ? 170 : 140,
                marginRight: theme.dir == "rtl" ? 23 : 20,
                borderRadius: 13,
              },
              item: {
                fontSize: 12,
                textAlign: "center",
              },
              divider: {
                backgroundColor: "#F1563C",
                height: 2,
                width: 180,
                marginInline: 4,
              },
            }}
          >
            <Menu.Target>
              <IconChevronDown color="Red" />
            </Menu.Target>

            <Menu.Dropdown>
              <Link to="/account-settings" style={{ textDecoration: "none" }}>
                <Menu.Item>{t("ACCOUNT_SETTINGS")}</Menu.Item>
              </Link>

              <Menu.Divider />
              <Menu.Item component="button" onClick={handleUserLogOut}>
                {t("LOGOUT")}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </MediaQuery>
    </>
  );
}

export default Navbar;
