import {
  Group,
  Stack,
  Title,
  Divider,
  Text,
  Select,
  Textarea,
  Button,
  Radio,
  MediaQuery,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { TrainersStyles } from "../../Styles";
import { useFormik } from "formik";
import { useQuery, useIsFetching } from "react-query";
import { useT } from "../../i18n";
import Loader from "../Loader";
import * as yup from "yup";
const BookTrainer = () => {
  const { classes } = TrainersStyles();
  const isFetching = useIsFetching();
  const t = useT();

  const { data, status, isLoading, isError, isFetched, refetch } = useQuery(
    "branches",
    () => {}
  );
  const validationSchema = yup.object().shape({
    location: yup.string().required("This field is mandatory"),
    trainer: yup.string().required("This field is mandatory"),
    date: yup.string().nullable().required("This field is mandatory"),
    package: yup.string().required("This field is mandatory"),
  });

  const formik = useFormik({
    initialValues: {
      location: "",
      trainer: "",
      date: null,
      notes: "",
      package: "",
    },
    validationSchema,
    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2));
    },
  });
  const handleChangeDate = (e: any) => {
    formik.setFieldValue("date", new Date(e).toLocaleDateString("en-US"));
  };

  const handleChangeTrainer = (e: any) => {
    formik.setFieldValue("trainer", e.target.value);
  };
  const handleChangeLocation = (e: any) => {
    formik.setFieldValue("location", e.target.value);
  };

  const handleChangePackage = (e: any) => {
    formik.setFieldValue("package", e.target.value);
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className={classes.appointmentContainer}>
        <Stack className={classes.title} spacing={0} mb={20}>
          <div>
            <Title order={2} align="left">
              {" "}
              {t("BOOK_APT")}
            </Title>
            <Divider color="#F92524" size="xl" mt={7} sx={{ width: 70 }} />
          </div>
        </Stack>

        <form onSubmit={formik.handleSubmit}>
          <Group
            position="center"
            align="center"
            spacing={100}
            className={classes.appointmentCard}
          >
            <Stack spacing={15}>
              <Text size={14}> {t("MANDATORY_FEILD")}</Text>

              <Select
                styles={{
                  error: {
                    height: 0,
                  },
                }}
                label={t("LOCATION") + " " + "*"}
                placeholder={t("SELECT") +" " + t("LOCATION")}
                //   rightSection={<IconChevronDown size={14} />}

                rightSectionWidth={30}
                onChange={handleChangeLocation}
                data={["React", "Angular", "Svelte", "Vue"]}
                error={formik.errors.location}
              />

              <Select
                styles={{
                  error: {
                    height: 5,
                  },
                }}
                label={t("TRAINER") + " " + "*"}
                placeholder={t("SELECT") + " " + t("TRAINER")}
                //   rightSection={<IconChevronDown size={14} />}
                rightSectionWidth={30}
                onChange={handleChangeTrainer}
                data={["React", "Angular", "Svelte", "Vue"]}
                error={formik.errors.trainer}
              />
              <DatePicker
                placeholder={t("SELECT") + " " + t("DATE")}
                label={t("DATE") + " " + "*"}
                inputFormat="MM/DD/YYYY"
                value={formik.values.date}
                onChange={handleChangeDate}
                styles={{
                  error: {
                    height: 0,
                    
                  },
                }}
                error={formik.errors.date}
              />

              <Textarea
                placeholder={t("ADD_NOTES")}
                label={t("NOTES")}
                autosize
                minRows={5}
                styles={{
                  rightSection: { pointerEvents: "none" },
                  label: {
                    fontSize: 14,
                  },
                  input: { fontSize: 14 },
                }}
              />
            </Stack>

            <Stack>
              <Stack ml={30} justify="flex-start" spacing={0}>
                <div className="d-flex flex-column justify-content-start">
                  <Title order={2} align="left">
                    {" "}
                    {t("SELECT_PACKAGE")}
                  </Title>
                  <Divider
                    color="#F92524"
                    size="xl"
                    mt={7}
                    sx={{ width: 70 }}
                  />
                </div>
              </Stack>
              <Radio.Group
                name="package"
                onChange={handleChangePackage}
                offset="lg"
                mb={30}
                error={formik.errors.package}
              >
                <div className={classes.radioContainer}>
                  {" "}
                  <Radio
                    styles={{
                      radio: {
                        border: "1px solid #F1563C",
                        "&:checked": {
                          backgroundColor: "white",
                          border: "1px solid #F1563C",
                        },
                      },
                      icon: {
                        color: "#F1563C",
                      },
                      inner: {
                        marginTop: 40,
                      },
                    }}
                    value="1"
                    label={
                      <Stack justify="center" spacing={0}>
                        <Text>Free Induction Sessions</Text>
                        <Group>
                          <Text size={40} color="default.2" weight="bolder">
                            2
                          </Text>{" "}
                          <Stack spacing={0} sx={{ width: 70 }}>
                            <Text>{t("SESSIONS")}</Text>
                            <Text size={10} color="#767373">
                              in balance
                            </Text>
                          </Stack>
                          <Text size={10} color="#767373">
                            Expiry in 12 Days
                          </Text>
                        </Group>
                      </Stack>
                    }
                  />
                </div>
                <div className={classes.radioContainer}>
                  {" "}
                  <Radio
                    styles={{
                      radio: {
                        border: "1px solid #F1563C",
                        "&:checked": {
                          backgroundColor: "white",
                          border: "1px solid #F1563C",
                        },
                      },
                      icon: {
                        color: "#F1563C",
                      },
                      inner: {
                        marginTop: 40,
                      },
                    }}
                    value="2"
                    label={
                      <Stack justify="center" spacing={0}>
                        <Text>Package Sessions</Text>
                        <Group>
                          <Text size={40} color="default.2" weight="bolder">
                            5
                          </Text>{" "}
                          <Stack spacing={0} sx={{ width: 70 }}>
                            <Text>{t("SESSIONS")}</Text>
                            <Text size={10} color="#767373">
                              in balance
                            </Text>
                          </Stack>
                          <Text size={10} color="#767373">
                            Expiry in 12 Days
                          </Text>
                        </Group>
                      </Stack>
                    }
                  />
                </div>
              </Radio.Group>
              <Group className={classes.buttons} position="right" spacing={30}>
                <Button>{t("CANCEL")}</Button>
                <Button type="submit">{t("CONFIRM")}</Button>
              </Group>
            </Stack>
          </Group>
        </form>
      </div>{" "}
    </>
  );
};

export default BookTrainer;
