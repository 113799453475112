import {
  Button,
  Card,
  Center,
  Group,
  Stack,
  Title,
  List,
  ThemeIcon,
  Text,
  Avatar,
} from "@mantine/core";
import { confrimClass } from "../Styles";
import { IconCheck, IconPhone } from "@tabler/icons";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { useT } from "../i18n/index";

function ClassBookingConfrim() {
  const t = useT();
  const { classes } = confrimClass();
  return (
    <Center mx={100} mt={30}>
      <Stack spacing={15}>
        <Group position="center" >
          <Card className={classes.left}>
            <Stack align="flex-start">
              <Group ml={10}>
              
                <ThemeIcon radius="xl" size="xl" color="#41B200">
                  <IconCheck />
                </ThemeIcon>{" "}
                <Text weight="bold" color="#00B530" size="xs">
                {t('BOOKING_CONFIRMED')}
                </Text>
              </Group>
              <Group sx={{ width: 370 }} >
                <Avatar size="lg" radius="xl"></Avatar>{" "}
                <Text size={15}>
                  {t('THANKYOU_TEXT')}
                </Text>
              </Group>
              <Group ml={20} mt={12}>
                <Text size="xs" color="default.8">
                {t('DATE')}
                </Text>
                <Text size="xs">13:30 - 14:00, On Monday, 23rd May</Text>
              </Group>
            </Stack>
          </Card>
          <Card className={classes.right}>
            <Stack align='flex-start'>
              <Title order={2} mt={20}>
               {t('GYM_PRECAUTIONS')}
              </Title>
              <List sx={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}  size={12} spacing={10} withPadding>
                <List.Item   className={classes.marker}>
                  {t("LIST_ITEM1")}
                </List.Item>
                <List.Item   className={classes.marker}>
                {t("LIST_ITEM2")}
                </List.Item>
                <List.Item  className={classes.marker}>
                {t("LIST_ITEM3")}
                </List.Item>
                <List.Item  className={classes.marker}>
                {t("LIST_ITEM4")}
                </List.Item>
                <List.Item className={classes.marker}>
                               {t("LIST_ITEM5")}
                </List.Item>
                <List.Item className={classes.marker}>
                {t("LIST_ITEM6")}
                </List.Item>
              </List>
            </Stack>
          </Card>
        </Group>
        <Card className={classes.bottom}>
          <Group position="apart">
            <Stack justify="center">
              <Text size="md">{t('NEED_HELP')}</Text>
              <Text size={12}>
                {t('HELP_TEXT')}
              </Text>
            </Stack>
            <Group>
              <IconPhone />
              <Text weight="bold" size="lg">
                0530255506
              </Text>
            </Group>
          </Group>
        </Card>
        <Button className={classes.btn}  component={Link} to="/classes">
        {t('CONFIRM')}
        </Button>
      </Stack>
    </Center>
  );
}

export default ClassBookingConfrim;
