import { Button, Center, Stack, Text, ThemeIcon } from "@mantine/core";
import React from "react";
import { useT, useSetLang } from "../../i18n/index";

import { Link, useSearchParams } from "react-router-dom";
import { IconCheck, IconX } from "@tabler/icons";

const OrderComplete = () => {
  const t = useT();
  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get("status");

  const renderMessage = () => {
    if (status == "ok") {
      return (
        <>
          <ThemeIcon radius={100} size={80} color="#41B200">
            <IconCheck size={50} />
          </ThemeIcon>{" "}
          <Text color="#00B530" size={25}>
            {t("success")}
          </Text>
          <Text mb={5} size={14}>
            {t("success_msg_package")}
          </Text>
        </>
      );
    }

    if (status == "unprocessable_entity" || !status) {
      return (
        <>
          {" "}
          <ThemeIcon radius={100} size={80} color="red">
            <IconX />
          </ThemeIcon>{" "}
          <Text color="#000000" size={25}>
            {t("ERROR")}
          </Text>
          <Text mb={5} size={14}>
            {searchParams.get("message")}
            {t("CONTACT_BANK")}
          </Text>
        </>
      );
    }
  };

  return (
    <Center className="success-modal">
      <Stack
        sx={{
          backgroundColor: "white",
          width: 520,
          height: 366,
          borderRadius: 10,
          boxShadow: "0px 3px 6px #00000029;",
        }}
        justify="center"
        align="center"
        spacing={5}
      >
        {renderMessage()}
        <Link to="/account-settings">
          <Button sx={{ width: 200, height: 40 }}> {t("CONTINUE")}</Button>
        </Link>
      </Stack>
    </Center>
  );
};

export default OrderComplete;
