import { Button, Center, Stack, Text, ThemeIcon } from "@mantine/core";
import React from "react";
import { useT, useSetLang } from "../../i18n/index";
import { IconCheck } from "@tabler/icons";
import { Link } from "react-router-dom";

const Success = () => {
  const t = useT();
  return (
    <Center className="success-modal">
      <Stack
        sx={{
          backgroundColor: "white",
          width: 520,
          height: 366,
          borderRadius: 10,
          boxShadow: "0px 3px 6px #00000029;",
        }}
        justify="center"
        align="center"
        spacing={5}
      >
        <ThemeIcon radius={100} size={80} color="#41B200">
          <IconCheck size={50} />
        </ThemeIcon>{" "}
        <Text color="#00B530" size={25}>
          {t("success")}
        </Text>
        <Text mb={5} size={14}>
          {t("success_msg")}
        </Text>
        <Link to="/account-settings">
          <Button sx={{ width: 200, height: 40 }}> {t("CONTINUE")}</Button>
        </Link>
      </Stack>
    </Center>
  );
};

export default Success;
