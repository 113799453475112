import React, { useState, useEffect, useContext, createContext } from "react";
import { getTokenByPassword } from "../api/auth";
import { setAxiosTokenInterceptor } from "../endpoints/api";
import { setAxiosTokenInterceptor as setAxiosToken } from "../utils/api";
import { IconColumnInsertLeft } from "@tabler/icons";
import { T, useT, useSetLang, useLang } from "../i18n/index";

export type AuthContextType = {
  user: user;
  loading: boolean;
  signin: Function;
  signout: Function;
};

export type user = {
  token: string;
  email: string;
  club_id: number;
  club_name: string;
};

const authContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function getUserFromLocalStorage() {
  const user = localStorage.getItem("user");
  try {
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

function useProvideAuth() {
  const userInit = getUserFromLocalStorage();
  const [user, setUser] = useState<any>(userInit);
  const [loading, setLoading] = useState(false);
  const setLang = useSetLang();

  const handleUser = (rawUser: any) => {
    if (rawUser) {
      const user = formatUser(rawUser);
      setLoading(false);
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", user.token);
      return user;
    } else {
      setLoading(false);
      setUser(false);
      return false;
    }
  };

  const signin = async (username: string, password: string) => {
    setLoading(true);
    const resp = await getTokenByPassword(username, password);
    return handleUser(resp.data);
  };

  const signout = () => {
    setAxiosTokenInterceptor(null);
    setAxiosToken(null);
    handleUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  useEffect(() => {
    if (user?.token) {
      setAxiosTokenInterceptor(user?.token);
      setAxiosToken(user?.token);
    } else {
      setAxiosTokenInterceptor(null);
      setAxiosToken(null);
    }
  }, [user]);

  return {
    user,
    loading,
    signin,
    signout,
  };
}

const formatUser = (user: any) => {
  return {
    // uid: user.uid,
    email: user?.user?.email,
    // name: user.displayName,
    // provider: user.providerData[0].providerId,
    // photoUrl: user.photoURL,
    club_id: user!.club!.id,
    club_name: user!.club!.name,
    token: user.access_token,
  };
};
