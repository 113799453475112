import axios from "axios";
import { useAuth } from "./../utils/auth";

const apiClient = axios.create({
  baseURL: "https://www.activgyms.com/en/wp-json/api/",
  headers: {
    accept: "application/json",
  },
});

export const setAxiosTokenInterceptor = async (
  accessToken: string | null
): Promise<void> => {
  if (accessToken) {
    apiClient.defaults.headers.common["Authorization"] = accessToken;
  } else {
    console.log("remove auth");
    delete apiClient.defaults.headers.common["Authorization"];
  }
};

export const api = {
  get: <T>(url: string, params?: object) =>
    apiClient.get<T>(url, {
      headers: {
        // authorization: localStorage.getItem("token"),
      },
      ...params,
    }),
  post: <T>(url: string, data: any) =>
    apiClient.post<T>(url, data, {
      headers: {
        // authorization: localStorage.getItem("token"),
      },
    }),
  patch: <T>(url: string, data: any) =>
    apiClient.patch<T>(url, data, {
      headers: {
        // authorization: localStorage.getItem("token"),
      },
    }),
  put: <T>(url: string, data: any) =>
    apiClient.put<T>(url, data, {
      headers: {
        // authorization: localStorage.getItem("token"),
      },
    }),
  delete: <T>(url: string) =>
    apiClient.delete<T>(url, {
      headers: {
        // authorization: localStorage.getItem("token"),
      },
    }),
};
