import React from "react";
import {
  Avatar,
  Card,
  Box,
  Anchor,
  Group,
  Stack,
  Text,
  Skeleton,
  MediaQuery,
} from "@mantine/core";
import { TrainersStyles } from "../../Styles";
import { useT } from "../../i18n";
import { Link } from "react-router-dom";
const TrainerCard = (props: trainerCardProps) => {
  const { classes } = TrainersStyles();
  const t = useT();
  return (
    <>
      <MediaQuery largerThan={500} styles={{ display: "none" }}>
        <div className={classes.card}>
          <Avatar
            className={classes.avatar}
            sx={{ padding: 10, backgroundColor: "white" }}
            styles={{
              image: {
                borderRadius: 100,
              },
            }}
            src={props.fetchedData.image}
            alt="trainer"
            size={120}
            radius={200}
          ></Avatar>
          <Stack spacing={2} justify="flex-start" align="flex-start">
            <Skeleton
              radius="xl"
              width="100%"
              visible={props.fetchedStatus === 1 ? true : false}
            >
              <Anchor
                component={Link}
                to={`/trainers/${props.fetchedData.id}`}
                state={props.fetchedData.id}
                size={15}
                color="default.2"
                underline
              >
                {props.fetchedData.name}
              </Anchor>{" "}
            </Skeleton>
            <Group noWrap spacing={2}>
              {" "}
              <Skeleton
                radius="xl"
                width="30%"
                visible={props.fetchedStatus === 1 ? true : false}
              >
                <Text size={10}>{t("EMAIL")}</Text>{" "}
              </Skeleton>
              <Skeleton
                radius="xl"
                visible={props.fetchedStatus === 1 ? true : false}
              >
                <Text sx={{ width: 200 }} size={10} color="default.8">
                  {props.fetchedData.email}
                </Text>
              </Skeleton>
            </Group>
          </Stack>
        </div>
      </MediaQuery>

      <MediaQuery smallerThan={500} styles={{ display: "none" }}>
        <div className={classes.card}>
          <Avatar
            className={classes.avatar}
            sx={{ padding: 10, backgroundColor: "white" }}
            styles={{
              image: {
                borderRadius: 100,
              },
            }}
            src={props.fetchedData.image}
            alt="trainer"
            size={120}
            radius={200}
          ></Avatar>
          <Stack spacing={5} justify="flex-start" align="flex-start">
            <Skeleton
              radius="xl"
              width="80%"
              visible={props.fetchedStatus === 1 ? true : false}
            >
              <Anchor
                component={Link}
                to={`/trainers/${props.fetchedData.id}`}
                state={props.fetchedData.id}
                size={20}
                color="default.2"
                underline
              >
                {props.fetchedData.name}
              </Anchor>{" "}
            </Skeleton>
            
            <Group noWrap spacing={0}>
              {" "}
              <Skeleton
                radius="xl"
                width="25%"
                visible={props.fetchedStatus === 1 ? true : false}
              >
                <Text size={12}>{t("EMAIL")}</Text>{" "}
              </Skeleton>
              <Skeleton
                radius="xl"
                width="60%"
                visible={props.fetchedStatus === 1 ? true : false}
              >
                <Text sx={{ width: 250 }} size={10} color="default.8">
                  {props.fetchedData.email}
                </Text>
              </Skeleton>
            </Group>
          </Stack>
        </div>
      </MediaQuery>
    </>
  );
};

type trainerCardProps = {
  fetchedData: any;
  fetchedStatus: number;
};
export default TrainerCard;
