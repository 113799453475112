import { Group, Stack } from "@mantine/core";
import CreditCards from "../Components/Dahboard/CreditCards";
import FutureAppointments from "../Components/Dahboard/FutureAppointments";
import LatestPayments from "../Components/Dahboard/LatestPayments";
import NextClass from "../Components/Dahboard/NextClass";
import { useQuery, useIsFetching } from "react-query";
import { getTransactions } from "../endpoints/api";
import Loader from "../Components/Loader";
import NoData from "../Components/NoData";

import { useGetProfile } from "../api/auth";

import { getAppointments } from "../endpoints/api";
import { format, add, eachDayOfInterval, addDays } from "date-fns";

function Dashboard() {
  const isFetching = useIsFetching();
  const { data, status, isLoading, isError, isFetched, refetch } = useQuery(
    "transactions",
    getTransactions,
    { refetchOnWindowFocus: true }
  );

  const { data: user, isLoading: isUserLoading } = useGetProfile();
  const userId = user?.id;

  const {
    data: apptData,
    status: apptStatus,
    isLoading: apptIsLoading,
    isError: apptIserror,
    isFetched: apptIsFetched,
    refetch: apptRefetch,
  } = useQuery(
    ["appointments", userId],
    () =>
      getAppointments(
        format(new Date(), "yyyy-MM-dd"),
        format(new Date(), "yyy-MM-dd"),
        userId
      ),
    {
      enabled: !!userId,
    }
  );

  return (
    <div className="mt-4">
      {isFetched && data !== undefined && data.data?.length !== 0 && (
        <Group position="center" align="flex-start" spacing={20} mb={30}>
          <Stack spacing={20}>
            {isLoading && <Loader />}
            <FutureAppointments
              fetchedData={
                apptData &&
                apptData.occurrences &&
                apptData.occurrences.length !== 0 &&
                apptData.occurrences[0]
              }
              fetchedStatus={apptIsFetched}
              noData={
                apptData &&
                apptData.occurrences &&
                apptData.occurrences.length !== 0
                  ? false
                  : true
              }
            />

            {/* <CreditCards fetchedStatus={isFetching} noData={false} /> */}
          </Stack>
          <Stack spacing={20}>
            <LatestPayments
              fetchedStatus={isFetching}
              fetchedData={
                Array.isArray(data?.data) && data?.data.length && data?.data[0]
              }
              noData={false}
            />

            {/* <NextClass fetchedStatus={isFetching} noData={false} /> */}
          </Stack>
        </Group>
      )}

      {isFetched &&
        !isLoading &&
        data !== undefined &&
        data.data?.length === 0 && (
          <Group position="center" align="flex-start" spacing={20} mb={30}>
            <Stack spacing={20}>
              <FutureAppointments
                fetchedData={
                  apptData &&
                  apptData.occurrences &&
                  apptData.occurrences.length !== 0 &&
                  apptData.occurrences[0]
                }
                fetchedStatus={apptIsFetched}
                noData={
                  apptData &&
                  apptData.occurrences &&
                  apptData.occurrences.length !== 0
                    ? false
                    : true
                }
              />
              {/* <CreditCards fetchedStatus={isFetching} noData={true} /> */}
            </Stack>
            <Stack spacing={20}>
              <LatestPayments
                fetchedStatus={isFetching}
                fetchedData={data.data.length != 0 && data.data[0]}
                noData={true}
              />
              {/* <NextClass fetchedStatus={isFetching} noData={true} /> */}
            </Stack>
          </Group>
        )}

      {isLoading && <Loader />}
    </div>
  );
}

export default Dashboard;
