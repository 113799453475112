import { bookClass } from "../Styles";
import {
  Card,
  Center,
  Group,
  Stack,
  Title,
  Text,
  Button,
  Divider,
  Avatar,
  Skeleton,
  Modal,
} from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { format, parseISO, isValid } from "date-fns";
import { enGB } from "date-fns/locale";
import { Image } from "@mantine/core";
import { useGetClass, usePatchClass } from "../api/classes";
import Logo from "./../assets/Logo.svg";
import { useIsFetching } from "react-query";
import { Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { useT, useLang } from "../i18n/index";
import { redirect } from "react-router-dom";
import { isAfter, differenceInHours } from "date-fns";

function ClassBooking() {
  const [opened, setOpened] = useState(false);
  const { classes } = bookClass();
  const isFetching = useIsFetching();
  const prefetched = useRef<boolean>();
  const { id = 0 } = useParams<{ id: string }>();
  const { data, isLoading } = useGetClass(+id);
  const occurrence = data?.occurrence;
  const mutation = usePatchClass(+id);
  const t = useT();
  const lang = useLang();

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(lang === "ar" ? "rtl" : "ltr");
  }, [lang]);

  useEffect(() => {
    if (
      occurrence &&
      mutation.isSuccess &&
      mutation.data.data?.occurrence?.errors?.length == 0
    ) {
      redirect(`/confirm/${occurrence?.id}`);
      // redirect(`/confirm/${occurrence?.id}`);
    }
  }, [mutation, occurrence]);

  useEffect(() => {}, [id]);

  return (
    <Center>
      <Card radius="sm" shadow="sm">
        <Group position="left" spacing={70} p={10}>
          <Stack>
            {isFetching ? (
              <Skeleton height={15} radius="md"></Skeleton>
            ) : (
              <div className="d-flex flex-column justify-content-start">
                <Title align="left" order={2}>
                  {occurrence?.service_title}
                </Title>
                <Divider color="#F92524" size="xl" mt={7} sx={{ width: 84 }} />
              </div>
            )}

            {isFetching ? (
              <Skeleton width="100%" height={145} radius="sm"></Skeleton>
            ) : (
              <Text
                size="xs"
                className={classes.desc}
                sx={{ width: 700, height: 85 }}
              >
                {occurrence?.service_description}
              </Text>
            )}
            <Group mb={30}>
              <Stack>
                <Skeleton radius="xl" visible={isFetching === 1 ? true : false}>
                  <Text size="xs">{t("GROUP_SIZE")}</Text>
                </Skeleton>
                <Skeleton radius="xl" visible={isFetching === 1 ? true : false}>
                  <Text size="xs">{t("LOCATION")}</Text>
                </Skeleton>
              </Stack>

              <Stack mr={20}>
                <Skeleton radius="xl" visible={isFetching === 1 ? true : false}>
                  <Text size="xs" color="default.8">
                    {occurrence?.service_group_size}
                  </Text>
                </Skeleton>
                <Skeleton radius="xl" visible={isFetching === 1 ? true : false}>
                  <Text size="xs" color="default.8">
                    {occurrence?.location_name}
                  </Text>
                </Skeleton>
              </Stack>

              <Stack>
                <Skeleton radius="xl" visible={isFetching === 1 ? true : false}>
                  <Text size="xs">{t("DURATION")}</Text>
                </Skeleton>
                <Skeleton radius="xl" visible={isFetching === 1 ? true : false}>
                  <Text size="xs">{t("TIME")}</Text>
                </Skeleton>
              </Stack>
              <Stack>
                <Skeleton radius="xl" visible={isFetching === 1 ? true : false}>
                  <Text size="xs" color="default.8">
                    {occurrence && occurrence.duration_in_hours
                      ? `${occurrence?.duration_in_hours} Hrs`
                      : ""}

                    {occurrence && occurrence.duration_in_minutes
                      ? `${occurrence?.duration_in_minutes} Mins`
                      : ""}
                  </Text>
                </Skeleton>
                <Skeleton radius="xl" visible={isFetching === 1 ? true : false}>
                  <Text size="xs" color="default.8">
                    {occurrence &&
                      format(
                        new Date(occurrence!.occurs_at),
                        "yyyy-MM-dd @ hh:mm aa"
                      )}
                  </Text>
                </Skeleton>
              </Stack>
            </Group>
            <Group spacing={20}>
              <Text size="xs">{t("TRAINER")}</Text>
              <Group spacing={10}>
                <Avatar size="lg" radius="xl" src="avatar.png" alt="it's me" />
                <Text size="xs" color="default.8">
                  {occurrence?.trainer_name}
                </Text>
              </Group>
            </Group>
          </Stack>
          <Stack className="d-flex justify-content-center align-items-center">
            <div>
              {isFetching ? (
                <Skeleton height={345} width={"100%"}></Skeleton>
              ) : occurrence && occurrence.service_logo_url !== null ? (
                <div
                  style={{
                    width: 350,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Image
                    src={occurrence.service_logo_url}
                    alt={occurrence?.service_title}
                  />
                </div>
              ) : (
                <div className="bg-dark p-5 hide-responsive">
                  <img src={Logo} />
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between book-cancel-btns-wrapper">
              {/* <Button className="text-white">{t("CANCEL")}</Button> */}

              {occurrence?.service_group_size ===
                occurrence?.attended_clients_count ||
                isAfter(new Date(), new Date(occurrence!.occurs_at)) ||
                (differenceInHours(
                  new Date(occurrence!.occurs_at),
                  new Date()
                ) > occurrence!.restrict_to_book_in_advance_time_in_hours && (
                  <Button onClick={() => setOpened(true)}>
                    {t("CONFIRM")}
                  </Button>
                ))}
            </div>
            {mutation.isSuccess &&
              mutation.data.data?.occurrence?.errors?.length == 0 && (
                <>
                  <Alert
                    icon={<IconAlertCircle size={16} />}
                    title="Success!"
                    color="lightgreen"
                    style={{ marginTop: "20px", width: "100%" }}
                  >
                    {t("BOOKED")}
                  </Alert>
                </>
              )}
            {mutation.isError ||
              (mutation.isSuccess &&
                !mutation.data.data.success &&
                mutation.data.data?.occurrence?.errors.length !== 0 && (
                  <Alert
                    icon={<IconAlertCircle size={16} />}
                    title="Error!"
                    color="red"
                    style={{ marginTop: "20px", width: "100%" }}
                  >
                    {mutation.data.data.data?.occurrence?.errors.map(
                      (error: any) => (
                        <div>{t(error.type)}</div>
                      )
                    )}
                  </Alert>
                ))}
          </Stack>
        </Group>
      </Card>
      <Modal
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("BOOKING_CONFIRMATION")}
      >
        <div className="">
          <div className="font-size-14">{t("CONFIRM_BOOKING_MSG")}</div>
          <div className="d-flex flex-row justify-content-around mt-3">
            <Button
              onClick={() => {
                // mutation.mutate(data!);
                setOpened(false);
              }}
              className="text-white"
            >
              {t("CANCEL")}
            </Button>
            <Button
              className="text-white"
              onClick={() => {
                mutation.mutate(data!);
                setOpened(false);
              }}
            >
              {t("CONFIRM")}
            </Button>
          </div>
        </div>
      </Modal>
    </Center>
  );
}

export default ClassBooking;
