import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  colorScheme: "light",
  colors: {
    default: [
      "#EFEFEF",
      "#000000",
      "#F1563C",
      "#FFFFFF",
      "#00000029",
      "#707070",
      "#181818",
      "#F92524",
      "#919191",
      "#000000",
    ],
  },
  focusRing: "never",
  fontFamily: "Poppins",
  cursorType: "pointer",
  defaultRadius: "xs",
  shadows: {
    md: "1px 1px 3px rgba(0, 0, 0, .25)",
    xl: "5px 5px 3px rgba(0, 0, 0, .25)",
  },

  fontSizes: {
    xs: 14,
    sm: 16,
    md: 20,
    lg: 24,
    xl: 35,
  },

  headings: {
    fontFamily: "Changa-Medium",
    fontWeight: "bold",
    sizes: {
      h1: { fontSize: 35 },
      h2: { fontSize: 24 },
    },
  },
  radius: {
    xs: 0,
    md: 24,
  },

  //core components styles

  components: {
    Button: {
      styles: {
        root: {
          backgroundColor: "#F1563C",
          width: 131,
          height: 41,
          fontWeight: 300,
          fontSize: 15,
          "&:hover": {
            backgroundColor: "#F1563C",
          },
        },
      },
    },

    TextInput: {
      styles: {
        // root: {
        //   width: 375,
        //   height: 32,
        //   boxShadow: "0px 3px 6px #00000029",
        //   border: "1px solid black",
        //   backgroundColor: "white",
        // },
        label: {
          fontSize: ".875rem",
          display: "flex",
          justifyContent: "start",
        },
        input: {
          width: "100%",
          height: "32px",
          paddingLeft: 10,
          fontSize: 15,
          "&::placeholder": {
            fontSize: "80%",
          },
          boxShadow: "0px 3px 6px #00000029",
          border: "1px solid #707070",
          backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box",
        },
      },
    },

    PasswordInput: {
      styles: {
        // root: {
        //   width: 375,
        //   height: 32,
        // },
        label: {
          fontSize: ".875rem",
          display: "flex",
          justifyContent: "start",
        },
        innerInput: {
          // width: "100%",
          // height: "32px",
          fontSize: 15,
          // paddingBottom: 10,
          "&::placeholder": {
            fontSize: "80%",
          },
          // boxShadow: "0px 3px 6px #00000029",
          // border: "1px solid #707070",
          // backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box",
        },
        input: {
          width: "100%",
          height: "32px",
          fontSize: 15,
          // paddingBottom: 10,
          "&::placeholder": {
            fontSize: "80%",
          },
          boxShadow: "0px 3px 6px #00000029",
          border: "1px solid #707070",
          backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box",
        },
      },
    },

    Text: {
      styles: {
        root: {
          display: "flex",
          justifyContent: "flex-start",
        },
      },
    },

    DatePicker: {
      styles: {
        label: {
          fontSize: ".875rem",
          display: "flex",
          justifyContent: "start",
        },
        input: {
          width: "100%",
          height: "32px",
          paddingLeft: 10,
          fontSize: 15,
          "&::placeholder": {
            fontSize: "80%",
          },
          boxShadow: "0px 3px 6px #00000029",
          border: "1px solid #707070",
          backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box",
        },
      },
    },

    Select: {
      styles: {
        rightSection: { pointerEvents: "none" },
        label: {
          fontSize: 14,
        },
        input: {
          width: "100%",
          height: "32px",
          paddingLeft: 10,
          fontSize: 15,
          "&::placeholder": {
            fontSize: "80%",
          },
          boxShadow: "0px 3px 6px #00000029",
          border: "1px solid #707070",
          backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box",
        },
        item: {
          fontSize: 14,
        },
        wrapper: { width: 360, height: 35 },
      },
    },

    Textarea: {
      styles: {
        input: {
          width: "100%",
          height: "32px",
          paddingLeft: 10,
          fontSize: 15,
          "&::placeholder": {
            fontSize: "80%",
          },
          boxShadow: "0px 3px 6px #00000029",
          border: "1px solid #707070",
          backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box",
        },
      },
    },
  },

  breakpoints: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1400,
  },
};
