import { createStyles } from "@mantine/core";
import { relative } from "path";

//Login
export const loginStyles = createStyles((theme, _params) => ({
  title: {
    "@media (max-width: 500px)": {
      fontSize: 25,
      marginBottom: 15,
    },
  },
  card: {
    width: 520,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 700px)": {
      maxWidth: 420,
    },
    "@media (max-width: 500px)": {
      maxWidth: 320,
      justifyContent: "center",
    },
  },

  input: {
    "@media (min-width: 700px)": {
      width: "100%",
    },
    "@media (max-width: 700px)": {
      width: "100%",
      height: 28,
    },
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  button: {
    width: 206,
    height: 42,
    display: "flex",
    justifyContent: "flex-start",
    letterSpacing: 0.7,
    "@media (max-width: 700px)": {
      maxWidth: 106,
      maxHeight: 32,
      fontSize: 10,
    },
    "@media (max-width: 500px)": {
      maxHeight: 22,
      fontSize: 8,
      paddingLeft: 20,
    },
  },
  button2: {
    width: 206,
    height: 42,
    display: "flex",
    justifyContent: "flex-start",
    letterSpacing: 0.7,
    "@media (max-width: 500px)": {
      maxWidth: 195,
      height: 42,
    },
  },
  password: {
    "@media (max-width: 700px)": {
      fontSize: 10,
      margin: 10,
    },
    "@media (max-width: 500px)": {
      fontSize: 10,
      margin: 5,
    },
  },
}));

//navbar
export const navStyles = createStyles((theme, _params) => ({
  wrapper: {
    backgroundColor: theme.colors.default[1],
    height: 120,
    color: "white",
    padding: 40,
    "@media (max-width: 500px)": {
      height: 80,
      padding: 20,
    },
  },
  img: {
    width: 216,
    height: 54,
    marginLeft: 50,
    "@media (max-width: 500px)": {
      width: 116,
      height: 44,
      marginLeft: 35,
    },
  },

  nav: {
    "@media (max-width: 940px)": {
      display: "none",
    },
  },

  navAnchor: {
    fontFamily: "Changa-Medium",
  },

  drawr: {
    display: "none",
    "@media (max-width: 940px)": {
      display: "block",
    },
  },
  social: {
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
}));

//classes
export const classCardStyle = createStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: 367,
    /* height: 217, */
    backgroundColor: "white",
  },
  button: {
    width: 131,
    height: 41,
    paddingBlock: 10,
    fontWeight: 300,

    letterSpacing: 0.7,
    marginTop: 16,
    alignSelf: "center",
    "&:hover": {
      backgroundColor: theme.colors.default[2],
      color: "#fff",
    },
  },
  branch: {
    marginInline: 100,
    "@media (max-width: 500px)": {
      fontSize: 20,
      marginInline: 30,
    },
  },
  title: {
    "@media (max-width: 500px)": {
      marginInline: 30,
    },
  },
}));

export const classEmpty = createStyles((theme) => ({
  wrapper: {
    width: 1233,
    height: 202,
    borderRadius: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const bookClass = createStyles(() => ({
  wrapper: {
    width: 1235,
    height: 372,
    borderRadius: 17,
    marginInline: 100,
    marginBlockStart: 30,
  },
  desc: {
    "@media (max-width: 500px)": {
      width: 350,
      height: 70,
      marginBottom: 50,
    },
  },
}));

export const confrimClass = createStyles(() => ({
  left: {
    width: 400,
    height: 300,
    borderRadius: 16,
    "@media (max-width: 1320px)": {
      width: "90%",
    },
  },
  btn: {
    alignSelf: "flex-end",
    "@media (max-width: 500px)": {
      alignSelf: "center",
    },
  },

  right: {
    width: 700,
    height: 300,
    borderRadius: 16,
    "@media (max-width: 1320px)": {
      width: "90%",
    },
  },
  bottom: {
    borderRadius: 16,
    height: 110,
    width: "100%",
    "@media (max-width: 1320px)": {
      width: "90%",
      marginLeft: 25,
    },
  },
  marker: {
    "&::marker": {
      color: "red",
    },
  },
}));

//Dashboard
export const Dashboard = createStyles(() => ({
  card: {
    width: 570,
    minHeight: 250,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "@media (max-width: 500px)": {
      maxWidth: 367,
      maxHeight: 150,
      padding: 15,
    },
  },
  title: {
    "@media (max-width: 500px)": {
      fontSize: 15,
    },
  },
  nodata: {
    backgroundColor: "#D9D9D9",
    width: 80,
    height: 80,
    borderRadius: 100,
    textAlign: "center",
    opacity: 0.5,
    "@media (max-width: 500px)": {
      width: 50,
      height: 50,
    },
  },
  nodataImg: {
    width: 50,
    height: 50,
    marginTop: 15,
    "@media (max-width: 500px)": {
      width: 30,
      height: 30,
    },
  },

  nodataText: {
    "@media (max-width: 500px)": {
      fontSize: 8,
      marginTop: 5,
    },
  },
}));

export const PackagesStyle = createStyles(() => ({
  card: {
    width: 366,
    height: 220,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    borderRadius: 20,
    gap: 5,
    gridGap: 5,
    marginRight: "5px",
    marginLeft: "5px",
    marginTop: "10px",
  },
  purchasedCard: {
    width: 351,
    height: 199,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    borderRadius: 20,
    border: "1px solid #F1563C",
  },
  title: {
    "@media (max-width: 500px)": {
      marginInline: 30,
    },
  },
  packagesScroll: {
    marginInline: 100,
    "@media (max-width: 500px)": {
      marginInline: 0,
    },
  },
}));

export const TrainersStyles = createStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 60,
    gap: 10,
    "@media (max-width: 500px)": {
      marginLeft: 30,
    },
  },

  search: {
    "@media (min-width: 500px)": {
      width: 1200,
    },
  },

  searchInput: {
    borderRadius: "5px",
    fontSize: "12px",
    width: 300,
    backgroundColor: "white",
    "@media (max-width: 1200px)": {
      width: 355,
    },
  },
  avatar: {
    right: 27,
    bottom: 2,
  },
  card: {
    backgroundColor: "white",
    width: 380,
    height: 108,
    paddingBlock: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "@media (max-width: 500px)": {
      width: 360,
      height: 80,
    },
  },

  detailsCard: {
    width: 1110,
    display: "flex",
    backgroundColor: "white",
    borderRadius: 20,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    // marginLeft: 50,
    "@media (max-width: 500px)": {
      width: 350,
      height: 500,
      flexDirection: "column",
    },
  },
  detailsAvatar: {
    right: 40,
    "@media (max-width: 500px)": {
      right: 10,
    },
  },

  appointmentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "flex-start",
    paddingInline: 50,
    paddingBlock: 35,
    "@media (max-width: 500px)": {
      paddingInline: 0,
    },
  },

  title: {
    marginLeft: 50,
    "@media (max-width: 500px)": {
      marginLeft: 20,
    },
  },
  appointmentCard: {
    width: 1150,
    height: 500,
    backgroundColor: "white",
    borderRadius: 20,
    marginBottom: 20,
    marginLeft: 50,
    "@media (max-width: 500px)": {
      width: 400,
      height: 1100,
      marginLeft: 0,
      marginBottom: 0,
      paddingBlock: 50,
    },
  },

  radioContainer: {
    width: 260,
    height: 120,
    backgroundColor: "White",
    border: "1px solid #9B9B9B",
    boxShadow: "0px 3px 6px #00000029",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 500px)": {
      marginLeft: 70,
    },
  },
  buttons: {
    "@media (max-width: 500px)": {
      marginRight: 50,
    },
  },
}));
