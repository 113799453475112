import { useFetch, useUpdate } from "../utils/reactQuery";
import { apiRoutes } from "../routes";
import { ClassInterface } from "../interfaces/index";
import { pathToUrl } from "../utils/router";

// export const useGetClassesList = () =>
//   useLoadMore<ClassInterface[]>(apiRoutes.getClasses);

export const useGetClass = (id: number | null) =>
  useFetch<ClassInterface>(pathToUrl(apiRoutes.getClass, { id }));

export const usePatchClass = (id: number) =>
  useUpdate<ClassInterface, ClassInterface>(
    pathToUrl(apiRoutes.joinClass, { id })
  );
