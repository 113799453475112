import { Carousel } from "@mantine/carousel";
import {
  Card,
  Group,
  Indicator,
  Stack,
  Text,
  Button,
  Skeleton,
  MediaQuery,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { classCardStyle } from "../Styles";
import NoData from "../Components/NoData";
import Dumbbell from "./../assets/dumbbell.png";
import Loader from "../Components/Loader";
import {
  format,
  parseISO,
  isValid,
  isAfter,
  differenceInHours,
} from "date-fns";
import { T, useT, useLang } from "../i18n/index";
import { theme } from "../theme";

function ClassCard(props: classCardProps) {
  const t = useT();
  const lang = useLang();

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(lang === "ar" ? "rtl" : "ltr");
  }, [lang]);

  const [fakeLoader, setFakeLoader] = useState(false);
  const { classes } = classCardStyle();

  const filteredData = props.fetchedData
    ? props.fetchedData.filter((el: any) => {
        //if no input the return the original
        if (props.input === "") {
          return el;
        }
        //return the item which contains the user input
        else {
          return el.service_title.toLowerCase().includes(props.input);
        }
      })
    : props.fetchedData;

  return (
    <div className="class-card-wrapper">
      <Stack>
        <MediaQuery smallerThan={500} styles={{ display: "none" }}>
          {
            <Carousel
              slideSize="10%"
              slideGap={20}
              align="start"
              slidesToScroll={3}
              withControls={false}
              speed={6}
              skipSnaps
              ml={96}
              mt={30}
              withIndicators
              styles={{
                indicator: {
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                  backgroundColor: "white",
                  border: "1px solid #F1563C",
                  "&[data-active]": {
                    backgroundColor: "#F1563C",
                  },
                },
                container: {
                  marginBottom: 50,
                },
              }}
            >
              {filteredData.map((item: any) => (
                <Carousel.Slide>
                  <Card shadow={"sm"} radius={24} className={classes.card}>
                    <div className="d-flex justify-content-between w-100 align-items-center mb-3 mt-2">
                      <Skeleton
                        width="70%"
                        visible={props.fetchedStatus === 1 ? true : false}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        <Text className="pl-2" size="md">
                          {item.service_title}
                        </Text>
                      </Skeleton>
                      {props.fetchedStatus == 1 ? (
                        <Skeleton
                          height={30}
                          circle
                          mb="xl"
                          visible={props.fetchedStatus === 1 ? true : false}
                        ></Skeleton>
                      ) : (
                        <Indicator
                          color={`${
                            item.service_group_size ===
                            item.attended_clients_count
                              ? "#DF1E08"
                              : "#16DF08"
                          }`}
                          size={20}
                          offset={3}
                          inline
                        >
                          {" "}
                        </Indicator>
                      )}
                    </div>
                    <Group position="left" align="flex-start" noWrap>
                      <Stack spacing={10}>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text align="end" size="xs">
                            {t("GROUP_SIZE")}{" "}
                          </Text>
                        </Skeleton>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text align="start" size="xs">
                            {t("LOCATION")}
                          </Text>
                        </Skeleton>
                      </Stack>
                      <Stack spacing={10}>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text color="default.2" size="xs">
                            <div>{item.service_group_size}</div>
                          </Text>
                        </Skeleton>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text color="default.2" size={14}>
                            <div>{item.location_name}</div>
                          </Text>
                        </Skeleton>
                      </Stack>
                      <Stack spacing={10}>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text size="xs">{t("DURATION")}</Text>
                        </Skeleton>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text size="xs">{t("TIME")}</Text>
                        </Skeleton>
                      </Stack>
                      <Stack spacing={10}>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text color="default.2" size="xs">
                            <div>
                              {item.duration_in_hours
                                ? `${item.duration_in_hours} Hrs`
                                : ""}

                              {item.duration_in_minutes
                                ? `${item.duration_in_minutes} Mins`
                                : ""}
                            </div>
                          </Text>
                        </Skeleton>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text size="xs" color="default.2">
                            {format(new Date(item.occurs_at), "hh:mm aa")}
                          </Text>
                        </Skeleton>
                      </Stack>
                    </Group>
                    <div className="d-flex flex-row justify-content-start  mt-3 w-100">
                      <Skeleton
                        width="35%"
                        visible={props.fetchedStatus === 1 ? true : false}
                      >
                        <Text align="start" size="xs">
                          {t("TRAINER")}
                        </Text>
                      </Skeleton>
                      <Skeleton
                        visible={props.fetchedStatus === 1 ? true : false}
                        width="100%"
                      >
                        <Text align="start" color="default.2" size={14}>
                          {item.trainer_name}
                        </Text>
                      </Skeleton>
                    </div>
                    {props.fetchedStatus == 1 && (
                      <Skeleton
                        width="50%"
                        visible={props.fetchedStatus === 1 ? true : false}
                        className="d-flex justify-content-center mt-3"
                      >
                        test
                      </Skeleton>
                    )}

                    {props.fetchedStatus === 0 && (
                      <Button
                        color="default.2"
                        className={classes.button}
                        component={Link}
                        to={`/classes/${item.id}`}
                        disabled={
                          !props.canBookClass ||
                          item.service_group_size ===
                            item.attended_clients_count ||
                          isAfter(new Date(), new Date(item.occurs_at)) ||
                          differenceInHours(
                            new Date(item.occurs_at),
                            new Date()
                          ) > item.restrict_to_book_in_advance_time_in_hours
                        }
                      >
                        {t("BOOK_NOW")}{" "}
                      </Button>
                    )}
                    {props.canBookClass &&
                      differenceInHours(new Date(item.occurs_at), new Date()) >
                        item.restrict_to_book_in_advance_time_in_hours && (
                        <Text size={10}>
                          {t("booking_available")}{" "}
                          {differenceInHours(
                            new Date(item.occurs_at),
                            new Date()
                          ) -
                            parseInt(
                              item.restrict_to_book_in_advance_time_in_hours
                            )}{" "}
                          {t("HOURS")}
                        </Text>
                      )}
                  </Card>
                </Carousel.Slide>
              ))}
            </Carousel>
          }
        </MediaQuery>

        <MediaQuery largerThan={500} styles={{ display: "none" }}>
          {
            <Carousel
              slideSize="10%"
              slideGap={10}
              align="center"
              slidesToScroll={1}
              withControls={false}
              speed={5}
              skipSnaps
              mt={30}
              withIndicators
              styles={{
                indicator: {
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                  backgroundColor: "white",
                  border: "1px solid #F1563C",
                  "&[data-active]": {
                    backgroundColor: "#F1563C",
                  },
                },
                container: {
                  marginBottom: 50,
                },
              }}
            >
              {filteredData.map((item: any) => (
                <Carousel.Slide>
                  <Card shadow={"sm"} radius={24} className={classes.card}>
                    <div className="d-flex justify-content-between w-100 align-items-center mb-3 mt-2">
                      <Skeleton
                        width="70%"
                        visible={props.fetchedStatus === 1 ? true : false}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        <Text className="" size="md">
                          {item.service_title}
                        </Text>
                      </Skeleton>
                      {props.fetchedStatus == 1 ? (
                        <Skeleton
                          height={30}
                          circle
                          mb="xl"
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          loader
                        </Skeleton>
                      ) : (
                        <Indicator
                          color={`${
                            item.service_group_size ===
                            item.attended_clients_count
                              ? "#DF1E08"
                              : "#16DF08"
                          }`}
                          size={20}
                          offset={3}
                          inline
                        >
                          {" "}
                        </Indicator>
                      )}
                    </div>
                    <Group position="left" align="flex-start" noWrap>
                      <Stack spacing={10}>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text align="end" size="xs">
                            {t("GROUP_SIZE")}{" "}
                          </Text>
                        </Skeleton>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text align="start" size="xs">
                            {t("LOCATION")}
                          </Text>
                        </Skeleton>
                      </Stack>
                      <Stack spacing={10}>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text color="default.2" size="xs">
                            <div>{item.service_group_size}</div>
                          </Text>
                        </Skeleton>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text color="default.2" size={14}>
                            <div>{item.location_name}</div>
                          </Text>
                        </Skeleton>
                      </Stack>
                      <Stack spacing={10}>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text size="xs">{t("DURATION")}</Text>
                        </Skeleton>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text size="xs">{t("TIME")}</Text>
                        </Skeleton>
                      </Stack>
                      <Stack spacing={10}>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text color="default.2" size="xs">
                            <div>
                              {item.duration_in_hours
                                ? `${item.duration_in_hours} Hrs`
                                : ""}

                              {item.duration_in_minutes
                                ? `${item.duration_in_minutes} Mins`
                                : ""}
                            </div>
                          </Text>
                        </Skeleton>
                        <Skeleton
                          visible={props.fetchedStatus === 1 ? true : false}
                        >
                          <Text size="xs" color="default.2">
                            {format(new Date(item.occurs_at), "hh:mm aa")}
                          </Text>
                        </Skeleton>
                      </Stack>
                    </Group>
                    <div className="d-flex flex-row justify-content-start  mt-3 w-100">
                      <Skeleton
                        width="35%"
                        visible={props.fetchedStatus === 1 ? true : false}
                      >
                        <Text align="start" size="xs">
                          {t("TRAINER")}
                        </Text>
                      </Skeleton>
                      <Skeleton
                        visible={props.fetchedStatus === 1 ? true : false}
                        width="100%"
                        ml={10}
                      >
                        <Text align="start" color="default.2" size={14}>
                          {item.trainer_name}
                        </Text>
                      </Skeleton>
                    </div>
                    {props.fetchedStatus == 1 && (
                      <Skeleton
                        width="50%"
                        visible={props.fetchedStatus === 1 ? true : false}
                        className="d-flex justify-content-center mt-3"
                      ></Skeleton>
                    )}

                    {props.fetchedStatus === 0 && (
                      <Button
                        color="default.2"
                        className={classes.button}
                        component={Link}
                        to={`/classes/${item.id}`}
                        disabled={
                          !props.canBookClass ||
                          item.service_group_size ===
                            item.attended_clients_count ||
                          isAfter(new Date(), new Date(item.occurs_at)) ||
                          differenceInHours(
                            new Date(item.occurs_at),
                            new Date()
                          ) > item.restrict_to_book_in_advance_time_in_hours
                        }
                      >
                        {t("BOOK_NOW")}{" "}
                      </Button>
                    )}
                  </Card>
                </Carousel.Slide>
              ))}
            </Carousel>
          }
        </MediaQuery>
        {filteredData.length == 0 && (
          <NoData img={Dumbbell} msg={t("NO_CLASS_DATA")} />
        )}
        {fakeLoader && <Loader />}
      </Stack>
    </div>
  );
}

export default ClassCard;

type classCardProps = {
  fetchedData: any;
  fetchedStatus: number;
  input: string;
  canBookClass: number | boolean;
};
