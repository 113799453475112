import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateOutlet from "./route";

import Login from "../Pages/Login";
import Home from "../Pages/Home";
import Navbar from "../Components/Navbar";
import Classes from "../Pages/Classes";
import Dashboard from "../Pages/Dashboard";
import Trainers from "../Pages/Trainers";
import Appointments from "../Pages/Appointments";
import Packages from "../Pages/Packages";
import PackagesTest from "../Pages/PackagesTest";
import ClassBooking from "../Pages/ClassBooking";
import ClassBookingConfrim from "../Pages/ClassBookingConfrim";
import AccountSettings from "../Components/UserProfile/AccountSettings";
import TrainerDetails from "../Components/Trainers/TrainerDetails";
import BookTrainer from "../Components/Trainers/BookTrainer";
import Success from "../Components/UserProfile/Success";
import SuccessPurchase from "../Components/UserProfile/SuccessPurchase";
import Error from "../Components/UserProfile/Error";
import ForgotPassword from "../Pages/ForgotPassword";
import NoMatch from "../Pages/NoMatch";
import OrderComplete from "../Components/UserProfile/OrderComplete";

export default function RoutesList(props: any) {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {/* <Route path="/home" element={<Home />} /> */}

      <Route path="/appointments" element={<PrivateOutlet />}>
        <Route path="" element={<Appointments />} />
      </Route>
      <Route path="/classes" element={<PrivateOutlet />}>
        <Route path="" element={<Classes />} />
      </Route>
      <Route path="/dashboard" element={<PrivateOutlet />}>
        <Route path="" element={<Dashboard />} />
      </Route>
      <Route path="/classes/:id" element={<PrivateOutlet />}>
        <Route path="" element={<ClassBooking />} />
      </Route>
      <Route path="/confirm/:id" element={<PrivateOutlet />}>
        <Route path="" element={<ClassBookingConfrim />} />
      </Route>
      <Route path="/packages" element={<PrivateOutlet />}>
        <Route path="" element={<Packages />} />
      </Route>
      <Route path="/packages-test" element={<PrivateOutlet />}>
        <Route path="" element={<PackagesTest />} />
      </Route>
      <Route path="/trainers" element={<PrivateOutlet />}>
        <Route path="" element={<Trainers />} />
      </Route>
      <Route path="/trainers/:id" element={<PrivateOutlet />}>
        <Route path="" element={<TrainerDetails />} />
      </Route>
      <Route path="/account-settings/success" element={<PrivateOutlet />}>
        <Route path="" element={<Success />} />
      </Route>
      <Route path="/packages/success" element={<PrivateOutlet />}>
        <Route path="" element={<SuccessPurchase />} />
      </Route>
      <Route path="/order-complete" element={<PrivateOutlet />}>
        <Route path="" element={<OrderComplete />} />
      </Route>

      <Route path="error" element={<PrivateOutlet />}>
        <Route path="" element={<Error />} />
      </Route>
      <Route path="/trainers/createappointment" element={<PrivateOutlet />}>
        <Route path="" element={<BookTrainer />} />
      </Route>

      {/* <Route path="dashboard" element={<Dashboard />} /> */}
      {/* <Route path="classes" element={<Classes />} />
      <Route path="booking" element={<ClassBooking />} />
      <Route path="confirm" element={<ClassBookingConfrim />} />
      <Route path="trainers" element={<Trainers />} />
      <Route path="appointments" element={<Appointments />} />
      <Route path="packages" element={<Packages />} /> */}
      <Route path="/account-settings" element={<PrivateOutlet />}>
        <Route path="" element={<AccountSettings />} />
      </Route>
      <Route path="*" element={<NoMatch />} />

      {/* <Route path="*">
        <NoMatch />
      </Route> */}
    </Routes>
  );
}
