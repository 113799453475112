import React, { useMemo, useState, useEffect } from "react";
import {
  format,
  add,
  eachDayOfInterval,
  addDays,
  formatRelative,
} from "date-fns";
import { Group } from "@mantine/core";
import { enGB, ar } from "date-fns/locale";
import ChevronRight from "./../assets/chevron-right.png";

function Calendar(props: calendarProps) {
  let differentMonth = false;

  if (
    format(props.getSelectedDays[0], "MMM") ===
    format(props.getSelectedDays[6], "MMM")
  )
    differentMonth = false;
  else differentMonth = true;

  const { lang } = props;
  const locale = lang === "ar" ? ar : enGB;
  return (
    <Group
      className="date-range-wrapper position-relative"
      position="center"
      spacing="lg"
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div
          className="mb-4"
          style={{ height: "50px", position: "absolute", top: "0" }}
        >
          <span>
            {format(props.getSelectedDays[0], "MMM", { locale })}{" "}
            {format(props.getSelectedDays[0], "Y")}
          </span>
          {differentMonth && <span>{" - "}</span>}
          {differentMonth && (
            <span>
              {format(props.getSelectedDays[6], "MMM", { locale })}{" "}
              {format(props.getSelectedDays[6], "Y")}
            </span>
          )}
        </div>
        <div className="d-flex flex-row align-items-center">
          {props.count > 0 && (
            <div
              className="cursor-pointer calendar-left-arrow"
              onClick={() => {
                props.setCount(props.count - 1);
                props.setActiveIndex(0);
              }}
            >
              <img className="ml-4 rotate-chevron mr-4" src={ChevronRight} />
            </div>
          )}

          {props.getSelectedDays.map((date: Date, index: number) => (
            <div
              data-index={index}
              onClick={() => props.setActiveIndex(index)}
              className={`class-date-item  ${
                props.activeIndex === index ? "active-date" : ""
              }`}
            >
              <span className="font-size-14">
                {format(date, "E", { locale })}
              </span>
              {format(date, "d", { locale })}
            </div>
          ))}
          <div
            className="cursor-pointer calendar-right-arrow"
            onClick={() => {
              props.setCount(props.count + 1);
              props.setActiveIndex(0);
            }}
          >
            <img className="ml-4" src={ChevronRight} />
          </div>
        </div>
      </div>
    </Group>
  );
}

export default Calendar;
type calendarProps = {
  fetchedData: any;
  loadingStatus: string;
  fetchedStatus: boolean;
  getSelectedDays: any;
  count: number;
  setCount: any;
  activeIndex: number;
  setActiveIndex: any;
  lang: string;
};
