import { Box } from "@mantine/core";

function NoMatch() {
  return (
    <div style={{ textAlign: "center", marginTop: "150px" }}>
      <div className="fof">
        <h1>Error 404</h1>
      </div>
    </div>
  );
}

export default NoMatch;
