import React from "react";
import { Text } from "@mantine/core";
import { Dashboard } from "../../Styles";
export const DashboardNodata = (props: dashboardNodataProps) => {
  const { classes } = Dashboard();
  return (
    <div className="container">
      <div className="d-flex flex-column justify content-start align-items-center">
        <div className={classes.nodata}>
          <img className={classes.nodataImg} src={props.img} />
        </div>
        <Text className={classes.nodataText} color="#919191" size={14} mt={10} weight={300}>
          {props.msg}
        </Text>
      </div>
    </div>
  );
};

type dashboardNodataProps = {
  img: any;
  msg: string;
};
