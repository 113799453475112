import React from "react";
import { Group, Card, Image, Text, Badge,Loader } from "@mantine/core";
import LoaderImg from "./../assets/loader.gif";

export default function CustomLoader() {
  return (
    <div className="container">
      <Card shadow="sm" p="lg" radius="sm" withBorder mt={40}>
        <div className="d-flex flex-column justify content-center align-items-center">
          <div className="">
          <Loader color="orange" />
          </div>

        </div>
      </Card>
    </div>
  )
}
