import React from "react";
import { Box, Text, Grid } from "@mantine/core";
import { Link } from "react-router-dom";
import SectionTitle from "./Components/SectionTitle";
import Loader from "../Loader";
import { useT } from "../../i18n";
import { useFetch } from "../../utils/reactQuery";
import { apiRoutes } from "../../routes";
import { pathToUrl } from "../../utils/router";
import { format, parseISO, isValid } from "date-fns";

const TransactionsHistory = () => {
  const { data, isLoading } = useFetch<{ data: any[]; count: number }>(
    pathToUrl(apiRoutes.getTransactions, {})
  );
  const t = useT();

  if (isLoading) return <Loader />;
  
  return (
    <Box sx={{ width: "95%" }}>
      {/*Title */}
      <SectionTitle text={t("transactionsHistory")} />
      <Box
        sx={{
          marginTop: "30px",
          overflowY: "scroll",
          height: "60vh",
          width: "100%",
        }}
      >
        {data?.count ? (
          data.data.map((trnx) => (
            <Grid
              key={trnx.id}
              gutter={0}
              sx={{
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                borderRadius: "10px",
                padding: "20px",
                marginBottom: "20px",
                marginInlineEnd: "10px",
              }}
            >
              <Grid.Col xs={12} sm={8} md={6}>
                {/*Posted Date */}
                <Grid>
                  <Grid.Col xs={6} lg={4} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>{t("postedDate")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={6} lg={4}>
                    <Text sx={{ color: "#919191", fontSize: ".775rem" }}>
                      {format(
                        new Date(trnx!.created_at),
                        "yyyy-MM-dd @ hh:mm aa"
                      )}
                    </Text>
                  </Grid.Col>
                </Grid>

                {/*Item */}
                <Grid>
                  <Grid.Col xs={6} lg={4} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>{t("item")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={6} lg={4}>
                    <Text sx={{ color: "#919191", fontSize: ".775rem" }}>
                      {trnx.product_name} - {trnx.section}
                    </Text>
                  </Grid.Col>
                </Grid>

                {/*Amount Paid */}
                <Grid>
                  <Grid.Col xs={6} lg={4} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>{t("amountPaid")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={6} lg={4}>
                    <Text sx={{ color: "#919191", fontSize: ".775rem" }}>
                      {trnx.amount}
                    </Text>
                  </Grid.Col>
                </Grid>

                {/*Payment Location */}
                <Grid>
                  <Grid.Col xs={6} lg={4} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>
                      {t("paymentLocation")}
                    </Text>
                  </Grid.Col>
                  <Grid.Col xs={6} lg={4}>
                    <Text sx={{ color: "#919191", fontSize: ".775rem" }}>
                      {trnx.location_name}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>

              <Grid.Col xs={12} sm={8} md={6}>
                {/*Payment Date */}
                <Grid>
                  <Grid.Col xs={6} lg={4} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>{t("paymentDate")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={6} lg={4}>
                    <Text
                      size="xs"
                      sx={{ color: "#919191", fontSize: ".775rem" }}
                    >
                      {format(
                        new Date(trnx!.created_at),
                        "yyyy-MM-dd @ hh:mm aa"
                      )}
                    </Text>
                  </Grid.Col>
                </Grid>

                {/*Quantity */}
                <Grid>
                  <Grid.Col xs={6} lg={4} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>{t("quantity")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={6} lg={4}>
                    <Text sx={{ color: "#919191", fontSize: ".775rem" }}>
                      {trnx.quantity}
                    </Text>
                  </Grid.Col>
                </Grid>

                {/*Discount */}
                <Grid>
                  <Grid.Col xs={6} lg={4} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>{t("discount")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={6} lg={4}>
                    <Text sx={{ color: "#919191", fontSize: ".775rem" }}>
                      {trnx.discounts_amount}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>

              {/*Ref */}
              <Grid.Col xs={12} py={6}>
                <Grid>
                  <Grid.Col xs={3} lg={2} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>{t("ref")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={9} lg={10}>
                    <Text sx={{ color: "#919191", fontSize: ".775rem" }}>
                      {trnx.reference} - {trnx.payment_id} - {trnx.id}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>

              {/*Receipt */}
              <Grid.Col xs={12} py={6}>
                <Grid>
                  <Grid.Col xs={3} lg={2} sx={{ color: "#1D1D1D" }}>
                    <Text sx={{ fontSize: ".775rem" }}>{t("receipt")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={9} lg={10}>
                    {/* <Link
                    to="/account-settings"
                    style={{
                      color: "#F1563C",
                      fontSize: ".775rem",
                      padding: "0px",
                    }}
                  >
                    VIEW
                  </Link> */}
                    <a target="_blank" href={trnx.receipt_url}>
                      <Text sx={{ color: "#F1563C", fontSize: ".775rem" }}>
                        {t("view")}
                      </Text>
                    </a>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          ))
        ) : (
          <Text>{t("noTransactions")}</Text>
        )}
      </Box>
    </Box>
  );
};

export default TransactionsHistory;
