import React from "react";
import { Box, RingProgress, Text, Grid, Center } from "@mantine/core";

import SectionTitle from "./Components/SectionTitle";

import { useGetProfile, useGeUserPackages } from "../../api/auth";
import { useT } from "../../i18n";
import Loader from "../Loader";

import { format, add, eachDayOfInterval, addDays } from "date-fns";

const dummyPackage = {
  id: 1,
  name: "3 Month Classes",
  desc: "3 month access to all clubs",
  packagePrice: "699.00",
  sessionsLeft: 5,
  expiryDate: "15/05/2022",
  startDate: "15/04/2022",
};

const AssignedPackages = () => {
  const { data, isLoading } = useGeUserPackages();
  const t = useT();

  if (isLoading) return <Loader />;

  return (
    <Box sx={{ width: "80%" }}>
      {/*Title */}
      <SectionTitle text={t("assignedPackages")} />
      {/*Packages */}
      <Box sx={{ marginTop: "50px" }}>
        {data?.count ? (
          data.client_service_packages.map((pgk) => (
            <Grid
              gutter="md"
              sx={{
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                borderRadius: "24px",
                padding: "20px",
                marginTop: "25px",
              }}
            >
              <Grid.Col xs={12} md={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Text
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: 550,
                      fontSize: "1rem",
                    }}
                  >
                    {pgk.service_package.name}
                  </Text>
                  <Text
                    sx={{
                      textTransform: "uppercase",
                      color: "#1D1D1D",
                      fontSize: ".875rem",
                      fontWeight: 300,
                    }}
                  >
                    {pgk.service_package?.description}
                  </Text>
                  <Text
                    sx={{
                      fontSize: ".875rem",
                    }}
                  >
                    Total Credits: {pgk?.total_credits}
                  </Text>
                  <Text
                    sx={{
                      fontSize: ".875rem",
                    }}
                  >
                    Used Credits: {pgk?.used_credits}
                  </Text>
                </div>
              </Grid.Col>
              <Grid.Col xs={12} md={5}>
                {/*Package Price */}
                {/* <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <Text size="xs">Package Price</Text>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Text size="xs">Package Price</Text>
                  </div>
                </div> */}
                <Grid gutter="xs">
                  <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                    <Text size="xs">Package Price</Text>
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <Text size="xs" sx={{ color: "#919191" }}>
                      {pgk.cost} SAR
                    </Text>
                  </Grid.Col>
                </Grid>

                {/*start Date */}
                <Grid justify="space-between">
                  <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                    <Text size="xs">{t("startDate")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <Text size="xs" sx={{ color: "#919191" }}>
                      {format(new Date(pgk.assigned_at), "yyyy-MM-dd")}
                    </Text>
                  </Grid.Col>
                </Grid>

                {/*Exipry Date */}
                <Grid justify="space-between">
                  <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                    <Text size="xs">{t("expiryDate")}</Text>
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <Text size="xs" sx={{ color: "#919191" }}>
                      {format(new Date(pgk.expires_at), "yyyy-MM-dd")}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          ))
        ) : (
          <Text>{t("NO_ASSIGNED_PACKAGES")}</Text>
        )}
      </Box>

      {/*Usage */}
      <Box sx={{ marginTop: "20px", display: "none" }}>
        <Grid>
          <Grid.Col xs={0} md={0} lg={4}></Grid.Col>
          <Grid.Col xs={12} sm={6} md={6} lg={4}>
            <Text sx={{ fontSize: "1rem" }}>{t("inductionSessions")}</Text>
            <Grid gutter="sm">
              <Grid.Col xs={2} lg={1}>
                <Text
                  sx={{ fontSize: "2rem", fontWeight: 550, color: "#F1563C" }}
                >
                  2
                </Text>
              </Grid.Col>
              <Grid.Col xs={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Text sx={{ fontSize: "1rem", lineHeight: 1 }}>
                    {t("sessions")}
                  </Text>
                  <Text sx={{ fontSize: ".675rem", color: "#767373" }}>
                    {t("inBalance")}
                  </Text>
                </div>
              </Grid.Col>
              <Grid.Col xs={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Text sx={{ fontSize: ".675rem", color: "#767373" }}>
                    {t("expiryIn")} 20 {t("days")}
                  </Text>
                </div>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={6} lg={4}>
            <Text sx={{ fontSize: "1rem" }}>{t("packageSessions")}</Text>
            <Grid gutter="sm">
              <Grid.Col xs={2} lg={1}>
                <Text
                  sx={{ fontSize: "2rem", fontWeight: 550, color: "#F1563C" }}
                >
                  {dummyPackage.sessionsLeft}
                </Text>
              </Grid.Col>
              <Grid.Col xs={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Text sx={{ fontSize: "1rem", lineHeight: 1 }}>
                    {t("sessions")}
                  </Text>
                  <Text sx={{ fontSize: ".675rem", color: "#767373" }}>
                    {t("inBalance")}
                  </Text>
                </div>
              </Grid.Col>
              <Grid.Col xs={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Text sx={{ fontSize: ".675rem", color: "#767373" }}>
                    {t("expiryIn")} 29 {t("days")}
                  </Text>
                </div>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Box>
    </Box>
  );
};

export default AssignedPackages;
